<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <nav-tabs-card>
        <template slot="content">
          <span class="md-nav-tabs-title">{{$t("label.items")}}:</span>
          <md-tabs class="md-success" md-alignment="left" @md-changed="toggleTab" :md-active-tab="activeTab"
          >
            <md-tab id="tab-bridges" :md-label="$t('label.bridges')" >
              <Bridges/>
            </md-tab>
            <md-tab id="tab-tunnels" :md-label="$t('label.tunnels')" >
              <Tunnels/>
            </md-tab>
          </md-tabs>
        </template>
      </nav-tabs-card>
    </div>
  </div>
</template>

<script>
import {NavTabsCard} from '@/components'
import Bridges from './bridgesTable'
import Tunnels from './tunnelsTable'

export default {

  data() {
    return {
      type: 'bridges',
      activeTab: "tab-bridges"
    }
  },

  components: {
    NavTabsCard,
    Bridges,
    Tunnels
  },
  created() {
    const {query} = this.$route
    if (!query.hasOwnProperty("type")) {
      this.$router.replace({name: 'BridgesAndTunnelsTables', query: {type: "bridges"}})
    } else {
      this.activeTab = `tab-${query.type}`
    }
  },
  methods: {
    toggleTab(evt) {
      let type = "bridges"
      if (evt !== "tab-bridges") {
        type = "tunnels"
      }
      this.$router.replace({name: 'BridgesAndTunnelsTables', query: {type}})
    }
  }
}
</script>

<style lang="scss">
.md-card-tabs .md-list {
  padding: 0;
}
</style>
